var _ = require('lodash');
var request = require('superagent');


// Date and time stuff
var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function timeTo24h (n) {
	n = (n && n === Number(n)) ? Math.round(n) : 0;
	return ('00' + (Math.floor(n / 60) % 24)).slice(-2) + ':' + ('00' + (n % 60)).slice(-2);
}

function daysTo24h (o) {
	var rtn = {};
	days.forEach(function (day) {
		rtn[day] = {
			open: o[day].open,
			from: timeTo24h(o[day].from),
			to: timeTo24h(o[day].to),
		};
	});
	return rtn;
}


// Locate the browser
function geoLocation (map, cb) {
	var initialLocation;

	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function (position) {
			initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			map.setCenter(initialLocation);
			return cb();
		}, function () {
			// Geo location failed; do nothing
		});
	}
	else {
		// Browser doesn't support geo location; do nothing
	}
}

// Loaders for TNT apis
// The TNT_API_URL is sourced from the network package and written into index.html
function loadLocations (callback) {
	request
		.get(TNT_API_URL + '/api/v1/locations/explorer/blueshyft/apple')
		.set('api_key', 'test')
		.end((err, res) => {
			if (err) return callback(err);
			return callback(null, res.body && res.body.locations);
		});
}

function getLocationData (shopId, callback) {
	request
		.get(TNT_API_URL + '/api/v1/locations/explorer/blueshyft/' + shopId)
		.set('api_key', 'test')
		.end((err, res) => {
			if (err) return callback(err);
			return callback(null, res.body && res.body.location);
		});
}


$(function () {

	var marker;
	window.infowindow = null;
	window.selectedLocation;

	function initialize () {

		var markers = [];
		var markersInBounds = [];

		var mapStyle = [{
			featureType: 'transit',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'poi',
			stylers: [{ visibility: 'off' }],
		}];

		var map = new google.maps.Map(document.getElementById('pe-map'), {
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			disableDoubleClickZoom: false,
			draggable: true,
			zoomControl: true,
			scrollwheel: false,
			styles: mapStyle,
		});

		var defaultBounds = new google.maps.LatLngBounds(
			new google.maps.LatLng(-33.8902, 151.1759),
			new google.maps.LatLng(-33.8474, 151.2631)
		);

		map.addListener('click', function () {
			if (window.infowindow) {
				window.infowindow.close();
			}
		});

		map.fitBounds(defaultBounds);

		// Perform the locate me
		$('.pe-search__button').click(function () {
			$('#pe-search__input').val('');
			$('#pe-search__input').attr('disabled', 'disabled');
			$('#pe-search__input').attr('placeholder', 'Locating...');
			$('.ion-navigate').removeClass('ion-navigate').addClass('loading');

			geoLocation(map, function () {
				$('.pe-wrap').removeClass('pe-wrap-start').addClass('pe-wrap-started');
				$('#button-icon').removeClass('loading');
				$('#pe-search__input').removeAttr('disabled', 'disabled');
				$('#pe-search__input').removeAttr('placeholder', 'Locating');
				$('#button-icon').addClass('ion-navigate');
				$('#pe-search__input').attr('placeholder', 'Search location');
			});

		});

		// Mobile only - toggle list view
		if ($(window).width() < 991) {
			$('.toggle-view').click(function () {
				$('.pe-search__result').toggleClass('hidden');
				$('.pe-filter-bar').toggleClass('active');
				$('.pe-map-wrap').toggleClass('hidden');
			});
		}


		// Show the cancel button when performing the search
		$('#pe-search__input').keypress(function (event) {
			if (this.value.length >= 0) {
				$('#icon-search').addClass('hidden');
				$('#icon-close').removeClass('hidden');

				if ($('.pe-filter-bar').hasClass('active')) {
					$('.pe-filter-bar').removeClass('active');
				}

				if ($('.pe-map-wrap').hasClass('hidden')) {
					$('.pe-map-wrap').removeClass('hidden');
				}

			} else {
				$('#icon-close').addClass('hidden');
			}
		});

		// Clear the search value when click on cancel
		$('#icon-close').click(function () {
			$('#pe-search__input').val('').focus();
			// $('.pe-search__result').addClass('hidden');
			$('#icon-close').addClass('hidden');
			$('#icon-search').removeClass('hidden');
			$('.pe-box').html('');
			if (window.infowindow) {
				window.infowindow.close();
			}
		});

		loadLocations(function (err, locations) {
			if (err) console.error(err);
			// console.log('Returned locations', locations);

			locations.forEach(function (loc) {
				var flattenedArray = _.flatten(loc);

				if (flattenedArray[4] && flattenedArray[3]) {
					marker = new google.maps.Marker({
						location: {
							shopId: flattenedArray[0],
							shopName: flattenedArray[1],
							address: flattenedArray[2],
							locType: 'blueshyft',
						},
						map: map,
						position: { lat: flattenedArray[4], lng: flattenedArray[3] },
					});
					markers.push(marker);

					marker.addListener('click', function () {
						window.selectedLocation = this.location.shopId;

						$("li[location-id='" + this.location.shopId + "'] > a").addClass('active');
						getLocationData(this.location.shopId, function (err, location) {
							if (err) console.error(err);
							if (err) console.error(err);

							var daysformatted = this.location.locType === 'blueshyft' ? daysTo24h(location.openingHours) : location.openingHours;

							var mondayHours = daysformatted.monday.open ? (daysformatted.monday.from + ' - ' + daysformatted.monday.to) : 'Closed';
							var tuesdayHours = daysformatted.tuesday.open ? daysformatted.tuesday.from + ' - ' + daysformatted.tuesday.to : 'Closed';
							var wednesdayHours = daysformatted.wednesday.open ? daysformatted.wednesday.from + ' - ' + daysformatted.wednesday.to : 'Closed';
							var thursdayHours = daysformatted.thursday.open ? daysformatted.thursday.from + ' - ' + daysformatted.thursday.to : 'Closed';
							var fridayHours = daysformatted.friday.open ? daysformatted.friday.from + ' - ' + daysformatted.friday.to : 'Closed';
							var saturdayHours = daysformatted.saturday.open ? daysformatted.saturday.from + ' - ' + daysformatted.saturday.to : 'Closed';
							var sundayHours = daysformatted.sunday.open ? (daysformatted.sunday.from + ' - ' + daysformatted.sunday.to) : 'Closed';

							var contentString = '<div class="info-window">'
								+ '<div class="loc-summary">'
									+ '<div class="loc-summary__title">' + this.location.shopName + '</div>'
									+ '<div class="loc-summary__byline">' + this.location.address + '</div>'
									+ '<div class="loc-summary__subtitle">Opening hours: </div>'
									+ '<ul class="loc-summary__list">'
										+ '<li><span class="day">Mon:</span><span class="time">' + mondayHours + '</span></li>'
										+ '<li><span class="day">Tue:</span><span class="time">' + tuesdayHours + '</span></li>'
										+ '<li><span class="day">Wed:</span><span class="time">' + wednesdayHours + '</span></li>'
										+ '<li><span class="day">Thu:</span><span class="time">' + thursdayHours + '</span></li>'
										+ '<li><span class="day">Fri:</span><span class="time">' + fridayHours + '</span></li>'
										+ '<li><span class="day">Sat:</span><span class="time">' + saturdayHours + '</span></li>'
										+ '<li><span class="day">Sun:</span><span class="time">' + sundayHours + '</span></li>'
									+ '</ul>'
								+ '</div>'
							+ '</div>';

							if (window.infowindow) {
								window.infowindow.close();
							}

							window.infowindow = new google.maps.InfoWindow({
								content: contentString,
							});

							var center = this.position;

							if ($(window).width() >= 800 && $(window).width() <= 1100) {
								map.panTo(center);
								map.panBy(-150, 0);
							} else {
								map.panTo(center);
								map.panBy(0, -100);
							}

							window.infowindow.open(map, this);

						}.bind(this));
					});
				}
			});
		});

		var input = /** @type {HTMLInputElement} */(
		document.getElementById('pe-search__input'));

		var options = {
			types: ['(regions)'],
			componentRestrictions: { country: 'au' },
		};

		// $('#pe-search__input').keypress(function(e) {
		//     if(e.which == 13) {
		//         $('#formfield-message') ? $('#formfield-message').css('display', 'none') : null
		//     }
		// })

		var searchBox = new google.maps.places.Autocomplete(input, options);

		google.maps.event.addListener(searchBox, 'place_changed', function () {
			$('.pe-search__result').addClass('hidden');

			var place = searchBox.getPlace();

			if (!place.geometry) {
				// $('.pe-search__input-field').append("<div id='formfield-message'>Please select a search option</div>")
				return;
			}

			// Hide the initial search overlay when performing the search
			$('.pe-wrap').removeClass('pe-wrap-start').addClass('pe-wrap-started');

			// Clear out previous search results
			$('.pe-search__result').html('');

			// Rebuild the search results list
			if ($(window).width() < 991) {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul></div>');
				$('.pe-search__result').addClass('hidden');
			} else {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul></div>');
			}

			if (place.length == 0) {
				return;
			}

			// For each place, get the icon, place name, and location.
			var bounds = new google.maps.LatLngBounds();

			bounds.extend(place.geometry.location);
			// Zooming out from the default zoom after a location is selected in the search box
			if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
				var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
				var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
				bounds.extend(extendPoint1);
				bounds.extend(extendPoint2);
			}

			map.fitBounds(bounds);
		});

		$('.pe-search__button').click(function () {
			if (!$('.pe-results').length) {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul></div>');
				$('.pe-search__result').addClass('hidden');
				if ($(window).width() > 991) {
					$('.pe-filter-bar').toggleClass('active');
				}

			}
		});

		google.maps.event.addListener(map, 'bounds_changed', function () {

			var bounds = map.getBounds();
			searchBox.setBounds(bounds);

			$('#newsagency-list').html('');

			if ($(window).width() > 991) {
				$('.pe-search__result').removeClass('hidden');
			}

			if (bounds) {
				markersInBounds = _.filter(markers, function (marker) {
					if (bounds.contains(marker.getPosition())) {
						var selectedMarker = (marker.location.shopId === window.selectedLocation) ? 'active' : '';
						$('#newsagency-list').append(
								'<li location-id="' + marker.location.shopId + '"><a href="javascript:;" class="' + selectedMarker + '"><div class="icon"><span class="ion-ios-location"></span></div><div class="name">' + marker.location.shopName + '</div><div class="address">' + marker.location.address + '</div></a></li>'
							);
						return marker;
					}
				});

				if (markersInBounds.length < 2) {
					var zoomLevel = map.getZoom();
					if (zoomLevel <= 7) return;
					map.setZoom(zoomLevel - 1);
				}
			}

			$("li[location-id='" + window.selectedLocation + "']").addClass('active');

			$('li[location-id]').click(function () {
				var shopId = this.getAttribute('location-id');

				if ($(window).width() < 991) {
					$('.pe-search__result').toggleClass('hidden');
					$('#toggle-icon').toggleClass('ion-ios-location').toggleClass('ion-navicon');
					$('.pe-filter-bar').removeClass('active');
					$('.pe-map-wrap').toggleClass('hidden');
				}

				var selectedMarker = _.filter(markersInBounds, function (marker) {
					if (shopId === marker.location.shopId) {
						getLocationData(shopId, function (err, location) {
							if (err) console.error(err);

							if (infowindow) {
								infowindow.close();
							}

							var daysformatted = marker.location.locType === 'blueshyft' ? daysTo24h(location.openingHours) : location.openingHours;

							var mondayHours = daysformatted.monday.open ? (daysformatted.monday.from + ' - ' + daysformatted.monday.to) : 'Closed';
							var tuesdayHours = daysformatted.tuesday.open ? daysformatted.tuesday.from + ' - ' + daysformatted.tuesday.to : 'Closed';
							var wednesdayHours = daysformatted.wednesday.open ? daysformatted.wednesday.from + ' - ' + daysformatted.wednesday.to : 'Closed';
							var thursdayHours = daysformatted.thursday.open ? daysformatted.thursday.from + ' - ' + daysformatted.thursday.to : 'Closed';
							var fridayHours = daysformatted.friday.open ? daysformatted.friday.from + ' - ' + daysformatted.friday.to : 'Closed';
							var saturdayHours = daysformatted.saturday.open ? daysformatted.saturday.from + ' - ' + daysformatted.saturday.to : 'Closed';
							var sundayHours = daysformatted.sunday.open ? (daysformatted.sunday.from + ' - ' + daysformatted.sunday.to) : 'Closed';

							var contentString = '<div class="info-window">'
								+ '<div class="loc-summary">'
									+ '<div class="loc-summary__title">' + marker.location.shopName + '</div>'
									+ '<div class="loc-summary__byline">' + marker.location.address + '</div>'
									+ '<div class="loc-summary__subtitle">Opening hours: </div>'
									+ '<ul class="loc-summary__list">'
										+ '<li><span class="day">Mon:</span><span class="time">' + mondayHours + '</span></li>'
										+ '<li><span class="day">Tue:</span><span class="time">' + tuesdayHours + '</span></li>'
										+ '<li><span class="day">Wed:</span><span class="time">' + wednesdayHours + '</span></li>'
										+ '<li><span class="day">Thu:</span><span class="time">' + thursdayHours + '</span></li>'
										+ '<li><span class="day">Fri:</span><span class="time">' + fridayHours + '</span></li>'
										+ '<li><span class="day">Sat:</span><span class="time">' + saturdayHours + '</span></li>'
										+ '<li><span class="day">Sun:</span><span class="time">' + sundayHours + '</span></li>'
									+ '</ul>'
								+ '</div>'
							+ '</div>';

							if (window.infowindow) {
								window.infowindow.close();
							}

							window.infowindow = new google.maps.InfoWindow({
								content: contentString,
							});

							var center = marker.position;
							window.selectedLocation = marker.location.shopId;
							map.panTo(center);

							if ($(window).width() >= 800 && $(window).width() <= 1100) {
								map.panTo(center);
								map.panBy(-150, 0);
							} else {
								map.panTo(center);
								map.panBy(0, -100);
							}

							window.infowindow.open(map, marker);
						});
					}

				});
			});
		});
	}

	google.maps.event.addDomListener(window, 'load', initialize);
});
